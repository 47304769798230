import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

import type { INotificationsRequest, INotificationsResponse } from '@skeleton/core/types';

export const useCoreUserNotificationApi = () => {
  const getUserNotificationPushes = async (params?: INotificationsRequest): Promise<INotificationsResponse> => {
    return await useApiAuthInstance('/api/notification/pushes', { params });
  };

  const readAllUserNotificationPushes = async (): Promise<INotificationsResponse> => {
    return await useApiAuthInstance('/api/notification/pushes/read/all', {
      method: 'POST',
    });
  };

  const readUserNotificationPushes = async (pushIds: string[]): Promise<INotificationsResponse> => {
    return await useApiAuthInstance('/api/notification/pushes/read', {
      method: 'POST',
      body: { pushIds },
    });
  };

  return {
    getUserNotificationPushes,
    readAllUserNotificationPushes,
    readUserNotificationPushes,
  };
};
