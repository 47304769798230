import { defineStore } from 'pinia';

import type { IPaginationMeta, IUserNotification, IUserNotificationWebSocketResponse } from '@skeleton/core/types';

interface IUserNotificationStoreState {
  items: IUserNotification[];
  meta: IPaginationMeta;
  userNotificationSubscription: any;
  newReadIds: string[];
  totalUnread: number;
}

export const useUserNotificationsStore = defineStore('userNotificationsStore', {
  state: (): IUserNotificationStoreState => ({
    items: [],
    meta: {
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalRows: 0,
    },
    totalUnread: 0,
    userNotificationSubscription: undefined,
    newReadIds: [],
  }),

  getters: {
    unreadItems({ items }: { items: IUserNotification[] }): IUserNotification[] {
      return items.filter(item => item.state === 1);
    },
  },

  actions: {
    subscribeUserNotificationSocket(): void {
      const profileStore = useProfileStore();

      if (profileStore.profile?.id) {
        const { createSubscription } = useWebSocket();

        this.userNotificationSubscription = createSubscription(
          `messenger:pushes#${profileStore.profile.id}`,
          this._newNotificationTrigger
        );
      }
    },

    unsubscribeUserNotificationSocket(): void {
      if (this.userNotificationSubscription) {
        this.userNotificationSubscription.unsubscribe();
        this.userNotificationSubscription.removeAllListeners();
      }
    },

    _newNotificationTrigger(webSocketResponse: IUserNotificationWebSocketResponse) {
      const { data = {} } = webSocketResponse;
      this.items.unshift(data.push);
      this.totalUnread += 1;
    },

    loadMore() {
      const newPage = (this.meta.page || 0) + 1;
      this.goToLoadUserNotifications(newPage, true);
    },

    async goToLoadUserNotifications(newPage?: number, more?: boolean) {
      const { getUserNotificationPushes } = useCoreUserNotificationApi();

      try {
        const { data, meta } = await getUserNotificationPushes({
          page: newPage || this.meta.page,
          perPage: this.meta.perPage,
        });

        const { totalUnread, ...other } = meta;

        this.items = more ? this.items.concat(data) : data;
        this.meta = other;

        if (!more) {
          this.totalUnread = totalUnread;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async goToReadAll() {
      const { readAllUserNotificationPushes } = useCoreUserNotificationApi();

      try {
        await readAllUserNotificationPushes();

        this._updateItemsAfterReadAll();
      } catch (err) {
        console.error(err);
      }
    },

    async goToReadByIds() {
      if (!this.newReadIds.length) return;

      const { readUserNotificationPushes } = useCoreUserNotificationApi();

      try {
        await readUserNotificationPushes(this.newReadIds);
        this.newReadIds = [];
      } catch (err) {
        console.error(err);
      }
    },

    markAsRead(id: string) {
      this.newReadIds.push(id);
      this.totalUnread -= 1;
    },

    _updateItemsAfterReadAll() {
      this.items = this.items.map(item => {
        return {
          ...item,
          state: 2,
        };
      });
    },
  },
});
