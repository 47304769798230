import { defineStore } from 'pinia';

interface ILoadingState {
  isLoading: boolean;
  routeName: string;
}

export const useLoadingStore = defineStore('loadingStore', {
  state: (): ILoadingState => ({
    isLoading: false,
    routeName: '',
  }),

  getters: {
    skeletonComponentName(state): string {
      return state.routeName.replace('locale-', '') + '-page';
    },
  },

  actions: {
    setLoadingState(isLoading: boolean) {
      this.isLoading = isLoading;
    },

    setRouteName(name: string) {
      this.routeName = name;
    },
  },
});
