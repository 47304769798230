import { defineStore } from 'pinia';

import type { IInvoice, IPaginationMeta, IPaymentGroup } from '~/skeleton/core/types';
import { ModalName } from '@skeleton/consts/modals';
import { PayType } from '@skeleton/consts/method';

type WalletModalTypes = 'deposit' | 'withdraw';

interface ITransactionStoreState extends Record<string, any> {
  walletModalType: WalletModalTypes | undefined;
  invoices: IInvoice[];
  invoicePageMeta: IPaginationMeta | undefined;
  isInvoiceLoading: boolean;
  selectedTab: 'cashBonuses' | 'freeSpins';
  depositMethods: IPaymentGroup[];
  withdrawMethods: IPaymentGroup[];
  depositLimitError: boolean;
  selectedCurrency: string;
  isLoadingDataMethods: boolean;
}

export const useTransactionStore = defineStore('transactionStore', {
  state: (): ITransactionStoreState => ({
    walletModalType: undefined,
    invoices: [],
    invoicePageMeta: undefined,
    isInvoiceLoading: true,
    bonusesData: [],
    bonusesMeta: undefined,
    isBonusesLoading: true,
    spins: [],
    spinsPageMeta: undefined,
    isSpinsLoading: true,
    selectedTab: 'cashBonuses',
    depositMethods: [],
    withdrawMethods: [],
    depositLimitError: false,
    selectedCurrency: '',
    isLoadingDataMethods: false,
  }),

  actions: {
    async getDepositMethods(currency?: string): Promise<void> {
      this.depositLimitError = false;
      const { getDepositMethods } = useCoreWalletApi();
      const { activeAccount } = useWalletStore();
      try {
        this.isLoadingDataMethods = true;
        const currentCurrency = currency || activeAccount?.currency || '';
        const groups = await getDepositMethods(currentCurrency);

        // сначала мануальные агенты
        this.depositMethods = prioritizeManualAgentMethods(groups);
        this.selectedCurrency = currentCurrency;
      } catch (err: any) {
        this.depositMethods = [];
        if (err.data?.error?.code === 13100) {
          this.depositLimitError = true;
          const { getLimits } = useLimitsStore();
          await getLimits();
        }
      } finally {
        this.isLoadingDataMethods = false;
      }
    },

    async getAllDepositMethods(): Promise<void> {
      this.isLoadingDataMethods = true;
      this.depositLimitError = false;
      const { getDepositMethods } = useCoreWalletApi();
      try {
        this.depositMethods = await getDepositMethods();
      } catch (err: any) {
        this.depositMethods = [];
        if (err.data?.error?.code === 13100) {
          this.depositLimitError = true;
          const { getLimits } = useLimitsStore();
          await getLimits();
        }
      } finally {
        this.isLoadingDataMethods = false;
      }
    },

    async getWithdrawMethods(currency?: string): Promise<void> {
      const { getWithdrawMethods } = useCoreWalletApi();
      const { activeAccount } = useWalletStore();
      try {
        this.isLoadingDataMethods = true;
        const currentCurrency = currency || activeAccount?.currency || '';

        const groups = await getWithdrawMethods(currentCurrency);

        // сначала мануальные агенты
        this.withdrawMethods = prioritizeManualAgentMethods(groups);
        this.selectedCurrency = currentCurrency;
      } catch {
        this.withdrawMethods = [];
      } finally {
        this.isLoadingDataMethods = false;
      }
    },

    async openWalletModal(modalType?: WalletModalTypes): Promise<void> {
      const { isTelegramUser } = storeToRefs(useProfileStore());
      const { openModalSync } = useModalStore();
      const { getLimits } = useLimitsStore();

      this.walletModalType = modalType;

      if (isTelegramUser.value) {
        openModalSync(ModalName.WALLET_TELEGRAM_APP, modalType);
        return;
      }

      const { getDepositBonusCode } = useBonusStore();
      const { activeAccount } = useWalletStore();
      const { taptic } = useHaptics();

      openModalSync(ModalName.WALLET, modalType);

      switch (modalType) {
        case PayType.DEPOSIT:
          await Promise.allSettled([this.getAllDepositMethods(), getDepositBonusCode(), getLimits()]);
          break;
        case PayType.WITHDRAW:
          await this.getWithdrawMethods(activeAccount?.currency);
      }
      this.selectedCurrency = activeAccount?.currency || '';
      taptic('soft');
    },
  },
});
