const PayMethod = {
  DEPOSIT_CASH_AGENT_METHOD: '0x.deposit.cash_agent',
  WITHDRAW_CASH_AGENT_METHOD: '0x.withdrawal.cash_agent',
  DEPOSIT_WALLET_DIRECT_METHOD: '0x.deposit.wallet_direct',
  DEPOSIT_WALLET_DIRECT_METHOD_TON: '0x.deposit.wallet_direct.ton',
  DEPOSIT_WALLET_DIRECT_METHOD_USDT: '0x.deposit.wallet_direct.usdt',
  DEPOSIT_WALLET_DIRECT_METHOD_ETH: '0x.deposit.wallet_direct.eth',
  DEPOSIT_WALLET_DIRECT_METHOD_BTC: '0x.deposit.wallet_direct.btc',
  WITHDRAW_WALLET_DIRECT_METHOD: '0x.withdrawal.wallet_direct',
  CHANGELLY_DEPOSIT_CARD: 'changelly.deposit.card',
  BILLBLEND_DEPOSIT_CARD: 'billblend.deposit',
  BILLBLEND_WITHDRAWAL_CARD: 'billblend.withdrawal',
  BKASHNAGAD_DEPOSIT: 'bkashnagad.deposit',
  BKASHNAGAD_WITHDRAWAL: 'bkashnagad.withdrawal',
  MANUAL_AGENT_DEPOSIT: 'manual-agent.deposit',
  MANUAL_AGENT_WITHDRAW: 'manual-agent.withdrawal',
  OPEN_BANKING_DEPOSIT: 'open-banking.deposit',
  OPEN_BANKING_WITHDRAW: 'open-banking.withdrawal',
  MONETIX_CARD_DEPOSIT: 'monetix.deposit.card',
  MONETIX_CARD_WITHDRAWAL: 'monetix.withdrawal.card',
  MONETIX_UPI_DEPOSIT: 'monetix.deposit.upi',
  MONETIX_UPI_WITHDRAWAL: 'monetix.withdrawal.upi',
  MONETIX_CARD_P2P_DEPOSIT: 'monetix.deposit.card-p2p',
  MONETIX_CARD_P2P_WITHDRAWAL: 'monetix.withdrawal.card-p2p',
  MONETIX_BINANCE_PAY_DEPOSIT: 'monetix.deposit.unify_Binance_Pay',
  MONETIX_BINANCE_PAY_WITHDRAWAL: 'monetix.withdrawal.unify_Binance_Pay',
  MONETIX_UPAY_DEPOSIT: 'monetix.deposit.unify_upay',
  MONETIX_UPAY_WITHDRAWAL: 'monetix.withdrawal.unify_upay',
  MONETIX_ROCKET_DEPOSIT: 'monetix.deposit.rocket-wallet',
  MONETIX_ROCKET_WITHDRAWAL: 'monetix.withdrawal.rocket-wallet',
  MONETIX_PIX_DEPOSIT: 'monetix.deposit.pix',
  MONETIX_PIX_WITHDRAWAL: 'monetix.withdrawal.pix',
  MONETIX_BANK_VENEZUELA_DEPOSIT: 'monetix.deposit.bank-transfer-venezuela',
  MONETIX_BANK_VENEZUELA_WITHDRAWAL: 'monetix.withdrawal.bank-transfer-venezuela',
  MONETIX_QAFPAY_DEPOSIT: 'monetix.deposit.qafpay',
  MONETIX_QAFPAY_WITHDRAWAL: 'monetix.withdrawal.qafpay',
  MONETIX_BLIK_DEPOSIT: 'monetix.deposit.blik',
  MONETIX_BLIK_WITHDRAWAL: 'monetix.withdrawal.blik',
  MONETIX_UNIFY_SOFORT_DEPOSIT: 'monetix.deposit.unify_sofort',
  MONETIX_UNIFY_SOFORT_WITHDRAWAL: 'monetix.withdrawal.unify_sofort',
  MONETIX_MULTIBANCO_DEPOSIT: 'monetix.deposit.multibanco',
  MONETIX_MULTIBANCOT_WITHDRAWAL: 'monetix.withdrawal.multibanco',
  MONETIX_SKRILL_WALLET_DEPOSIT: 'monetix.deposit.skrill-wallet',
  MONETIX_SKRILL_WALLET_WITHDRAWAL: 'monetix.withdrawal.skrill-wallet',
  MONETIX_BKASH_WALLET_DEPOSIT: 'monetix.deposit.bkash-wallet',
  MONETIX_BKASH_WALLET_WITHDRAWAL: 'monetix.withdrawal.bkash-wallet',
  MONETIX_NAGAD_WALLET_DEPOSIT: 'monetix.deposit.nagad-wallet',
  MONETIX_NAGAD_WALLET_WITHDRAWAL: 'monetix.withdrawal.nagad-wallet',
  MONETIX_SEPA_OPEN_BANKING_DEPOSIT: 'monetix.deposit.open-banking-sepa',
  MONETIX_SEPA_OPEN_BANKING_WITHDRAWAL: 'monetix.withdrawal.open-banking-sepa',
  MONETIX_SEPA_DEPOSIT: 'monetix.deposit.sepa',
  MONETIX_SEPA_WITHDRAWAL: 'monetix.withdrawal.sepa',
  MONETIX_BANK_TRANSFER_DEPOSIT: 'monetix.deposit.bank-transfer-turkey',
  MONETIX_BANK_TRANSFER_WITHDRAWAL: 'monetix.withdrawal.bank-transfer-turkey',
  MONETIX_P2P_DEPOSIT: 'monetix.deposit.papara-wallet-p2p',
  MONETIX_P2P_WITHDRAWAL: 'monetix.withdrawal.papara-wallet-p2p',
  MONETIX_P2C_DEPOSIT: 'monetix.deposit.papara-wallet-p2c',
  MONETIX_P2C_WITHDRAWAL: 'monetix.withdrawal.papara-wallet-p2c',
  MONETIX_PAYFIX_DEPOSIT: 'monetix.deposit.payfix-wallet',
  MONETIX_PAYFIX_WITHDRAWAL: 'monetix.withdrawal.payfix-wallet',
  MONETIX_GCASH_DEPOSIT: 'monetix.deposit.gcash-wallet',
  MONETIX_GCASH_WITHDRAWAL: 'monetix.withdrawal.gcash-wallet',
  MONETIX_PAYMAYA_DEPOSIT: 'monetix.deposit.paymaya-wallet',
  MONETIX_PAYMAYA_WITHDRAWAL: 'monetix.withdrawal.paymaya-wallet',
  MONETIX_NETELLER_DEPOSIT: 'monetix.deposit.neteller-wallet',
  MONETIX_NETELLER_WITHDRAWAL: 'monetix.withdrawal.neteller-wallet',
  MONETIX_UNIFY_PAYSAFECASH_DEPOSIT: 'monetix.deposit.unify_paysafecash',
  MONETIX_UNIFY_PAYSAFECASH_WITHDRAWAL: 'monetix.withdrawal.unify_paysafecash',
  MONETIX_UNIFY_RAPID_TRANSFER_DEPOSIT: 'monetix.deposit.unify_rapidtransfer',
  MONETIX_UNIFY_RAPID_TRANSFER_WITHDRAWAL: 'monetix.withdrawal.unify_rapidtransferh',
  MONETIX_IMPS_DEPOSIT: 'monetix.deposit.imps',
  MONETIX_PHONEPE_WALLET_DEPOSIT: 'monetix.deposit.phonepe-wallet',
  MONETIX_NETWALLET_DEPOSIT: 'monetix.deposit.netwallet',
  MONETIX_NETWALLET_WITHDRAWAL: 'monetix.withdrawal.netwallet',
  MONETIX_ARGENTINS_DEPOSIT: 'monetix.deposit.argentina',
  MONETIX_ARGENTINS_WITHDRAWAL: 'monetix.withdrawal.argentina',
  MATRIX_CARD_DEPOSIT: 'matrix.deposit.card',
  MATRIX_CARD_WITHDRAWAL: 'matrix.withdrawal.card',
  MATRIX_MOBILE_COM_DEPOSIT: 'matrix.deposit.mobilecom',
  MATRIX_MOBILE_COM_WITHDRAWAL: 'matrix.withdrawal.mobilecom',
  MATRIX_HUMO_DEPOSIT: 'matrix.deposit.cardp2p.humo',
  MATRIX_HUMO_WITHDRAWAL: 'matrix.withdrawal.cardp2p.humo',
  MATRIX_UZCARD_DEPOSIT: 'matrix.deposit.cardp2p.uzcard',
  MATRIX_UZCARD_WITHDRAWAL: 'matrix.withdrawal.cardp2p.uzcard',
  MATRIX_DEPOSIT_VISA: 'matrix.deposit.card.visa',
  MATRIX_WITHDRAWAL_VISA: 'matrix.withdrawal.card.visa',
  MATRIX_DEPOSIT_CARD_MC: 'matrix.deposit.card.mc',
  MATRIX_WITHDRAWAL_CARD_MC: 'matrix.withdrawal.card.mc',
};

const FormField = {
  PLAYER_NAME: 'playerName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  CITY: 'city',
  POSTAL_CODE: 'postalCode',
  ADDRESS: 'address',
  COUNTRY: 'country',
  PLAYER_EMAIL: 'playerEmail',
  IDENTITY_DOC_NUMBER: 'identify_doc_number',
};

// костыль для скейлинга картинки метода
const scaledPayMethodIcon = [];

// в этих методах раскрываем список провайдеров
const showProviders = [PayMethod.BILLBLEND_DEPOSIT_CARD, PayMethod.BILLBLEND_WITHDRAWAL_CARD];

const PayMethodToTitle = {
  [PayMethod.WITHDRAW_CASH_AGENT_METHOD]: 'Cash agent',
  [PayMethod.DEPOSIT_CASH_AGENT_METHOD]: 'Cash agent',
  [PayMethod.CHANGELLY_DEPOSIT_CARD]: 'Deposit crypto via bank card',
  [PayMethod.BILLBLEND_DEPOSIT_CARD]: 'Deposit fiat via bank card',
  [PayMethod.BILLBLEND_WITHDRAWAL_CARD]: 'Withdrawal fiat via bank card',
  [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD]: 'Cryptocurrencies',
  [PayMethod.WITHDRAW_WALLET_DIRECT_METHOD]: 'Cryptocurrencies',
  [PayMethod.BKASHNAGAD_DEPOSIT]: 'Nagad & bKash fast',
  [PayMethod.BKASHNAGAD_WITHDRAWAL]: 'Nagad & bKash fast',
  [PayMethod.MANUAL_AGENT_DEPOSIT]: 'Nagad & bKash direct',
  [PayMethod.MANUAL_AGENT_WITHDRAW]: 'Nagad & bKash direct',
  [PayMethod.OPEN_BANKING_DEPOSIT]: 'Open Banking',
  [PayMethod.OPEN_BANKING_WITHDRAW]: 'Open Banking',
  [PayMethod.MONETIX_UPI_DEPOSIT]: 'UPI',
  [PayMethod.MONETIX_UPI_WITHDRAWAL]: 'UPI',
  [PayMethod.MONETIX_CARD_P2P_DEPOSIT]: 'P2P money transfer',
  [PayMethod.MONETIX_CARD_P2P_WITHDRAWAL]: 'P2P money transfer',
  [PayMethod.MONETIX_BINANCE_PAY_DEPOSIT]: 'Binance pay',
  [PayMethod.MONETIX_BINANCE_PAY_WITHDRAWAL]: 'Binance pay',
  [PayMethod.MONETIX_CARD_DEPOSIT]: 'Open Banking',
  [PayMethod.MONETIX_CARD_WITHDRAWAL]: 'Open Banking',
  [PayMethod.MONETIX_UPAY_DEPOSIT]: 'Upay',
  [PayMethod.MONETIX_UPAY_WITHDRAWAL]: 'Upay',
  [PayMethod.MONETIX_ROCKET_DEPOSIT]: 'Rocket',
  [PayMethod.MONETIX_ROCKET_WITHDRAWAL]: 'Rocket',
  [PayMethod.MONETIX_PIX_DEPOSIT]: 'PIX',
  [PayMethod.MONETIX_PIX_WITHDRAWAL]: 'PIX',
  [PayMethod.MONETIX_BANK_VENEZUELA_DEPOSIT]: 'Bank transfer',
  [PayMethod.MONETIX_BANK_VENEZUELA_WITHDRAWAL]: 'Bank transfer',
  [PayMethod.MONETIX_QAFPAY_DEPOSIT]: 'QafPay',
  [PayMethod.MONETIX_QAFPAY_WITHDRAWAL]: 'QafPay',
  [PayMethod.MONETIX_BLIK_DEPOSIT]: 'Blik',
  [PayMethod.MONETIX_BLIK_WITHDRAWAL]: 'Blik',
  [PayMethod.MONETIX_UNIFY_SOFORT_DEPOSIT]: 'Sofort',
  [PayMethod.MONETIX_UNIFY_SOFORT_WITHDRAWAL]: 'Sofort',
  [PayMethod.MONETIX_MULTIBANCO_DEPOSIT]: 'Multibanco',
  [PayMethod.MONETIX_MULTIBANCOT_WITHDRAWAL]: 'Multibanco',
  [PayMethod.MONETIX_SKRILL_WALLET_DEPOSIT]: 'Skrill Wallet',
  [PayMethod.MONETIX_SKRILL_WALLET_WITHDRAWAL]: 'Skrill Wallet',
  [PayMethod.MONETIX_BKASH_WALLET_DEPOSIT]: 'BKash',
  [PayMethod.MONETIX_BKASH_WALLET_WITHDRAWAL]: 'BKash',
  [PayMethod.MONETIX_NAGAD_WALLET_DEPOSIT]: 'Nagad',
  [PayMethod.MONETIX_NAGAD_WALLET_WITHDRAWAL]: 'Nagad',
  [PayMethod.MONETIX_SEPA_OPEN_BANKING_DEPOSIT]: 'Open Banking',
  [PayMethod.MONETIX_SEPA_OPEN_BANKING_WITHDRAWAL]: 'Open Banking',
  [PayMethod.MONETIX_SEPA_DEPOSIT]: 'SEPA',
  [PayMethod.MONETIX_SEPA_WITHDRAWAL]: 'SEPA',
  [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_TON]: 'TON',
  [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_USDT]: 'USDT',
  [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_BTC]: 'BTC',
  [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_ETH]: 'ETH',
  [PayMethod.MONETIX_BANK_TRANSFER_DEPOSIT]: 'Bank Transfer',
  [PayMethod.MONETIX_BANK_TRANSFER_WITHDRAWAL]: 'Bank Transfer',
  [PayMethod.MONETIX_P2P_DEPOSIT]: 'Papara',
  [PayMethod.MONETIX_P2P_WITHDRAWAL]: 'Papara',
  [PayMethod.MONETIX_P2C_DEPOSIT]: 'Papara Auto',
  [PayMethod.MONETIX_P2C_WITHDRAWAL]: 'Papara Auto',
  [PayMethod.MONETIX_PAYFIX_DEPOSIT]: 'PayFix',
  [PayMethod.MONETIX_PAYFIX_WITHDRAWAL]: 'PayFix',
  [PayMethod.MONETIX_GCASH_DEPOSIT]: 'GCash',
  [PayMethod.MONETIX_GCASH_WITHDRAWAL]: 'GCash',
  [PayMethod.MONETIX_PAYMAYA_DEPOSIT]: 'PayMaya',
  [PayMethod.MONETIX_PAYMAYA_WITHDRAWAL]: 'PayMaya',
  [PayMethod.MONETIX_NETELLER_DEPOSIT]: 'Neteller',
  [PayMethod.MONETIX_NETELLER_WITHDRAWAL]: 'Neteller',
  [PayMethod.MONETIX_UNIFY_PAYSAFECASH_DEPOSIT]: 'Paysafecash',
  [PayMethod.MONETIX_UNIFY_PAYSAFECASH_WITHDRAWAL]: 'Paysafecash',
  [PayMethod.MONETIX_UNIFY_RAPID_TRANSFER_DEPOSIT]: 'Rapid',
  [PayMethod.MONETIX_UNIFY_RAPID_TRANSFER_WITHDRAWAL]: 'Rapid',
  [PayMethod.MONETIX_IMPS_DEPOSIT]: 'IMPS',
  [PayMethod.MONETIX_PHONEPE_WALLET_DEPOSIT]: 'PhonePe',
  [PayMethod.MONETIX_NETWALLET_DEPOSIT]: 'NetWallet',
  [PayMethod.MONETIX_NETWALLET_WITHDRAWAL]: 'NetWallet',
  [PayMethod.MONETIX_ARGENTINS_DEPOSIT]: 'Bank transfers in Argentina',
  [PayMethod.MONETIX_ARGENTINS_WITHDRAWAL]: 'Bank transfers in Argentina',
  [PayMethod.MATRIX_CARD_DEPOSIT]: 'Card',
  [PayMethod.MATRIX_CARD_WITHDRAWAL]: 'Card',
  [PayMethod.MATRIX_MOBILE_COM_DEPOSIT]: 'Mobile Operators',
  [PayMethod.MATRIX_MOBILE_COM_WITHDRAWAL]: 'Mobile Operators',
  [PayMethod.MATRIX_HUMO_DEPOSIT]: 'HUMO',
  [PayMethod.MATRIX_HUMO_WITHDRAWAL]: 'HUMO',
  [PayMethod.MATRIX_UZCARD_DEPOSIT]: 'UZCARD',
  [PayMethod.MATRIX_UZCARD_WITHDRAWAL]: 'UZCARD',
  [PayMethod.MATRIX_DEPOSIT_VISA]: 'Visa',
  [PayMethod.MATRIX_WITHDRAWAL_VISA]: 'Visa',
  [PayMethod.MATRIX_DEPOSIT_CARD_MC]: 'Mastercard',
  [PayMethod.MATRIX_WITHDRAWAL_CARD_MC]: 'Mastercard',
};

const PAY_SYSTEMS = [
  PayMethod.OPEN_BANKING_DEPOSIT,
  PayMethod.MONETIX_UPI_DEPOSIT,
  PayMethod.MONETIX_UPAY_DEPOSIT,
  PayMethod.MONETIX_UNIFY_SOFORT_DEPOSIT,
  PayMethod.MONETIX_BINANCE_PAY_DEPOSIT,
  PayMethod.MONETIX_SKRILL_WALLET_DEPOSIT,
  PayMethod.MONETIX_SEPA_DEPOSIT,
  PayMethod.MONETIX_ROCKET_DEPOSIT,
  PayMethod.MONETIX_QAFPAY_DEPOSIT,
  PayMethod.MONETIX_PIX_DEPOSIT,
  PayMethod.MONETIX_SEPA_OPEN_BANKING_DEPOSIT,
  PayMethod.MONETIX_NAGAD_WALLET_DEPOSIT,
  PayMethod.MONETIX_MULTIBANCO_DEPOSIT,
  PayMethod.MONETIX_CARD_P2P_DEPOSIT,
  PayMethod.MONETIX_CARD_DEPOSIT,
  PayMethod.MONETIX_BLIK_DEPOSIT,
  PayMethod.MONETIX_BKASH_WALLET_DEPOSIT,
  PayMethod.MONETIX_BANK_VENEZUELA_DEPOSIT,
  PayMethod.BILLBLEND_DEPOSIT_CARD,
  PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_USDT,
  PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_TON,
  PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_ETH,
  PayMethod.DEPOSIT_WALLET_DIRECT_METHOD_BTC,
  PayMethod.DEPOSIT_CASH_AGENT_METHOD,
  PayMethod.MONETIX_IMPS_DEPOSIT,
  PayMethod.MONETIX_NETWALLET_DEPOSIT,
  PayMethod.MONETIX_PHONEPE_WALLET_DEPOSIT,
];

const FOOTER_PAY_LIST = [
  'bitcoin',
  'ethereum',
  'tether',
  'tron',
  'visa',
  'gpay',
  'masterCard',
  'cashAgent',
  'uzcard',
  'neteller',
  'astroPay',
  'bankTransfer',
  'skrill',
  'jeton',
];

const PayType = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
};

export {
  PayMethod,
  FormField,
  scaledPayMethodIcon,
  showProviders,
  PayMethodToTitle,
  PAY_SYSTEMS,
  FOOTER_PAY_LIST,
  PayType,
};
