export const changeDate = (
  dates: string[],
  filters: Partial<{ dateFrom: string | undefined; dateTo: string | undefined }>
): void => {
  const [startDate, endDate] = dates;

  if (dates.length === 2 && (startDate !== filters.dateFrom || endDate !== filters.dateTo)) {
    filters.dateFrom = startDate;
    filters.dateTo = endDate;
  } else {
    filters.dateFrom = undefined;
    filters.dateTo = undefined;
  }
};

export const resetFilters = (filters: Record<string, any>, defaultFilters: Record<string, any>): void => {
  Object.assign(filters, defaultFilters);
};

export const changeFilters = (newSpinResult: string, filters: Record<string, any>, changeField: string): void => {
  filters.page = 1;
  filters[changeField] = newSpinResult;
};
export const isFiltersChanged = (filters: Record<string, any>, defaultFilters: Record<string, any>) => {
  return Object.keys(defaultFilters).some((key) => filters[key] !== defaultFilters[key]);
};

export const generateOptions = (
  items: any[],
  getContent: (globalContent: any, localeContent: any, key: string) => string,
  globalContent: any,
  localeContent: any,
  keyPrefix: string,
  optionsDefaultValue: { value: string; code: string }
): { value: string; code: string }[] => {
  const storeOptions = items.map((item) => {
    const label = getContent(globalContent, localeContent, `${keyPrefix}.${item.id}`);
    return { value: label, code: item.id };
  });
  return [optionsDefaultValue, ...storeOptions];
};
