import { defineNuxtPlugin } from 'nuxt/app';
import Hammer from 'hammerjs';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      hammer: Hammer,
    },
  };
});
