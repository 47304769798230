<template>
  <img
    v-if="props.notLazy"
    ref="targetImage"
    :key="imageSrc"
    :src="imageSrc"
    :width="width"
    :height="height"
    alt=""
    data-not-lazy
    draggable="false"
    @error="handleLoadingError"
    @load="emit('imageLoaded')"
  />

  <img v-lazy-load 
    v-else
    :key="`lazy-${imageSrc}`"
    :data-src="imageSrc"
    :width="width"
    :height="height"
    alt=""
    draggable="false"
    @error="handleLoadingError"
    @load="emit('imageLoaded')"
  />
</template>

<script setup lang="ts">
  const props = defineProps<{
    src?: string;
    notLazy?: boolean;
    defaultImage?: string;
    width?: number;
    height?: number;
  }>();

  const useDefaultImg = ref<boolean>(false);
  const imageSrc = computed(() => {
    return useDefaultImg.value ? props.defaultImage : props.src;
  });

  const handleLoadingError = (): void => {
    if (props.defaultImage) useDefaultImg.value = true;

    emit('loadingError');
  };

  const targetImage = ref<HTMLImageElement>();

  const emit = defineEmits(['imageLoaded', 'loadingError']);

  onMounted(() => {
    if (props.notLazy && props.defaultImage && targetImage.value?.complete && !targetImage.value?.naturalWidth)
      useDefaultImg.value = true;
  });
</script>
