import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { useAdditionParams } from '@skeleton/core/assets/useAdditionParams';

const LOG_TIMEOUT = 2000; // 2s

export const useApiAuthInstance = async (url: string, options?: any): Promise<any> => {
  const profileStore = useProfileStore();
  const traceId = uuidv4();

  const addParams = useAdditionParams();

  const { source, platform } = addParams.getRequestParams(url);

  let token = profileStore.getSessionToken();
  let newUrl = url;

  if (options?.params) {
    const stringifyParams = queryString.stringify(options.params, { arrayFormat: 'bracket' });
    newUrl = `${url}?${stringifyParams}`;
  }

  const time = Date.now();

  const newOptions = {
    ...options,
    params: undefined,
    headers: { ...options?.headers, 'x-trace-id': traceId, ...(source && { source }), ...(platform && { platform }) },
    credentials: 'omit',
    retry: 0,
    async onResponse() {
      const timeOut = Date.now() - time;

      if (timeOut > LOG_TIMEOUT) {
        console.warn(`Time-out: ${traceId}, ${timeOut}\n ${url}`);
      }
    },
    async onResponseError({ response }: any) {
      if (response.status === 401 || response.status === 403) {
        if (profileStore.isTelegramUser) {
          await profileStore.loginFromTelegramApp('onResponseError');
        } else {
          if (profileStore.getSessionToken()) await profileStore.removeSession();
          const { localizePath } = useProjectMethods();
          const router = useRouter();
          await router.push(localizePath('/?sign-in=true'));
        }
      }
    },
  };

  if (import.meta.server) {
    console.log('API INSTANCE TOKEN: ', token);
    console.log('API INSTANCE OPTIONS: ', newOptions);
    console.log('API INSTANCE REQUEST URL: ', newUrl);
  }

  if (token) {
    newOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (token && profileStore.isTokenExpired()) {
    token = await profileStore.refreshToken();
    if (token) {
      newOptions.headers.Authorization = `Bearer ${token}`;
    } else {
      delete newOptions.headers.Authorization;
    }
  }

  try {
    return await $fetch(newUrl, newOptions);
  } catch (e: any) {
    if (!e.response) {
      console.warn(`No response: ${url}\n`, traceId);
    }
    throw e;
  }
};
