import { defineAsyncComponent } from 'vue';
import { useModal, type UseModalReturnType } from 'vue-final-modal';

import {
  ModalName,
  MODALS,
  MODALS_URL,
  ONLY_GUEST_MODALS,
  ONLY_LOGGED_MODALS,
  SYNC_MODALS,
  MODALS_CONTENT,
} from '@skeleton/consts/modals';

import type { ModalNameTypes } from '@skeleton/consts/modals';
import type { IModalsContent } from '~/types';

type WalletModalTypes = 'deposit' | 'withdraw';

interface IModals extends Partial<Record<ModalNameTypes, UseModalReturnType<any>>> {}

interface IModalStoreState {
  modals: IModals;
  modalsUrl: Partial<Record<ModalNameTypes, string>>;
  openingModals: string[];
  modalsSync: Partial<Record<ModalNameTypes, boolean>>;
  isProcessing: boolean;
}

interface IOpenModalParams {
  modalQueryParam?: string;
  props?: Record<string, any>;
  eventHandlers?: Record<string, (...args: any[]) => void>;
}

export const useModalStore = defineStore('modalStore', {
  state: (): IModalStoreState => ({
    modals: MODALS,
    modalsUrl: MODALS_URL,
    openingModals: [],
    modalsSync: {},
    isProcessing: false,
  }),

  actions: {
    accessToOpen(modalName: ModalNameTypes): boolean {
      const { isLoggedIn } = useProfileStore();

      if (ONLY_GUEST_MODALS[modalName]) return !isLoggedIn;
      if (ONLY_LOGGED_MODALS[modalName]) return isLoggedIn;

      return true;
    },

    async openModal(modalName: ModalNameTypes, params: IOpenModalParams = {}): Promise<void> {
      if (!this.accessToOpen(modalName) || this.openingModals.includes(modalName)) {
        return;
      }

      this.openingModals.push(modalName);

      if (!this.modals[modalName]) {
        this.modals[modalName] = await this._createModal(modalName, params);
      } else {
        const { attrs } = this.modals[modalName].options;
        this.modals[modalName].patchOptions({
          attrs: { ...(attrs as object), ...params.props, ...params.eventHandlers },
        });
      }

      const queryKey = this.modalsUrl[modalName];

      if (queryKey) {
        await this._addModalQuery(queryKey, params.modalQueryParam);
      }

      this.modals[modalName]?.open();

      this.openingModals = this.openingModals.filter(item => item !== modalName);
    },

    async closeModal(modalName: ModalNameTypes): Promise<void> {
      this.modals[modalName]?.close();

      if (this.modalsUrl[modalName]) {
        await this._removeModalQuery(modalName);
      }
    },

    async closeAllModals(): Promise<void> {
      const { query } = useRoute();
      const newQuery = { ...query };

      Object.values(this.modalsUrl).forEach(queryKey => {
        if (newQuery[queryKey]) {
          delete newQuery[queryKey];
        }
      });

      Object.keys(this.modals).forEach(modalName => {
        const key = modalName as ModalNameTypes;
        this.modals[key]?.close();
      });

      const router = useRouter();
      await router.replace({ query: newQuery });
    },

    async checkOpenedModals(): Promise<void> {
      const route = useRoute();
      const queryArr = Object.keys(route.query);

      for (const queryKey of queryArr) {
        const modalName = Object.keys(this.modalsUrl).find(
          name => this.modalsUrl[name as ModalNameTypes] === queryKey
        ) as ModalNameTypes | undefined;

        if (!modalName || this.openingModals.includes(modalName) || this.modalsSync[modalName]) continue;

        if (!this.accessToOpen(modalName)) {
          await this._removeModalQuery(modalName);
        } else if (modalName === ModalName.WALLET || modalName === ModalName.WALLET_TELEGRAM_APP) {
          const queryParam = route.query[modalName] as string;
          await this._handleWalletModal(queryParam);
          break;
        } else {
          if (SYNC_MODALS[modalName]) {
            this.openModalSync(modalName, route.query[queryKey] as string);
          } else {
            await this.openModal(modalName, { modalQueryParam: route.query[queryKey] as string });
          }
          break;
        }
      }
    },

    openModalSync(modalName: ModalNameTypes, modalQueryParam?: string): void {
      this.modalsSync[modalName] = true;

      const queryKey = this.modalsUrl[modalName];

      if (queryKey) {
        this._addModalQuery(queryKey, modalQueryParam);
      }
    },

    closeModalSync(modalName: ModalNameTypes): void {
      if (this.modalsSync[modalName]) {
        this._removeModalQuery(modalName);
      }

      this.modalsSync[modalName] = false;
    },

    async _handleWalletModal(queryParam: string | undefined): Promise<void> {
      const modalType = ['deposit', 'withdraw'].includes(queryParam || '')
        ? (queryParam as WalletModalTypes)
        : undefined;

      const { openWalletModal } = useTransactionStore();
      await openWalletModal(modalType);
    },

    async _addModalQuery(queryKey: string, modalQueryParam?: string): Promise<void> {
      const route = useRoute();
      if (route.name === 'sport') {
        return;
      }

      const router = useRouter();
      const { query } = useRoute();
      const newQuery = { ...query };

      Object.keys(query).forEach(queryName => {
        if (Object.values(this.modalsUrl).includes(queryName)) {
          if (this.modals[queryName as ModalNameTypes]) {
            this.modals[queryName as ModalNameTypes]?.close();
          }

          delete newQuery[queryName];
        }
      });

      await router.replace({ query: { ...newQuery, [queryKey]: modalQueryParam || 'true' } });
    },

    async _removeModalQuery(modalName: ModalNameTypes): Promise<void> {
      const route = useRoute();
      if (route.name === 'sport') {
        return;
      }

      const router = useRouter();
      const { query } = useRoute();

      const queryKey = this.modalsUrl[modalName];

      if (!queryKey) {
        return;
      }

      const newQuery = { ...query, [queryKey]: undefined };

      await router.replace({ query: newQuery });
    },

    async _createModal(modalName: ModalNameTypes, params: IOpenModalParams = {}): Promise<ReturnType<typeof useModal>> {
      const modalComponent = defineAsyncComponent(async () => {
        try {
          return await import(`../components/modal/${modalName}.vue`);
        } catch (err) {
          return import(`../components/modal/${modalName}.vue`);
        }
      });

      const content = await this._getContentForModal(modalName);

      return useModal({
        component: modalComponent,
        attrs: {
          ...params.props,
          ...params.eventHandlers,
          ...content,
        },
      });
    },

    async _getContentForModal(modalName: ModalNameTypes) {
      const contentName = MODALS_CONTENT[modalName];
      if (!contentName) return {};

      const { popupsData, defaultLocalePopupsData } = useGlobalStore();

      return {
        currentLocaleData: popupsData?.[contentName as keyof IModalsContent] as Maybe<
          IModalsContent[keyof IModalsContent]
        >,
        defaultLocaleData: defaultLocalePopupsData?.[contentName as keyof IModalsContent] as Maybe<
          IModalsContent[keyof IModalsContent]
        >,
      };
    },
  },
});
