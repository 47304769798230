import { defineNuxtPlugin } from 'nuxt/app';
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  (function(d, w, c) {
    w.ChatraID = runtimeConfig.public.chatraId || '';
    w.ChatraSetup = {startHidden: true};
    var s = d.createElement('script');
    w[c] = w[c] || function() {
      (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');
});
