type ModalNameTypes = (typeof ModalName)[keyof typeof ModalName];
type ModalTypes = Partial<Record<string, boolean>>;

const ModalName = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  CHANGE_PASS: 'change-pass',
  RESET_PASS: 'reset-pass',
  FORGOT_PASS: 'forgot-pass',
  PHONE: 'phone',
  DEPOSIT_ERROR: 'deposit-error',
  DEPOSIT_SUCCESS: 'deposit-success',
  DEPOSIT_STATUS: 'deposit-status',
  REFERRAL_TRANSFER: 'referral-transfer',
  CONFIRM: 'confirm',
  CONFIRM_EMAIL: 'confirm-email',
  RESET_EMAIL_SEND: 'reset-email-send',
  FIAT: 'fiat',
  WALLET: 'wallet',
  GAMES_FILTER: 'games-filter',
  CONFIRM_SESSION_LOGOUT: 'confirm-session-logout',
  WALLET_TELEGRAM_APP: 'wallet-telegram-app',
  TG_NEWS: 'telegram-news',
  AVATARS: 'avatars',
  GLOBAL_SEARCH: 'global-search',
  GEO_POPUP: 'geo-popup',
} as const;

const MODALS = Object.fromEntries(Object.values(ModalName).map(modalName => [modalName, undefined])) as Record<
  (typeof ModalName)[keyof typeof ModalName],
  undefined
>;

const MODALS_URL: Partial<Record<ModalNameTypes, string>> = {
  [ModalName.SIGN_IN]: 'sign-in',
  [ModalName.SIGN_UP]: 'sign-up',
  [ModalName.CHANGE_PASS]: 'change-pass',
  [ModalName.RESET_PASS]: 'reset-pass',
  [ModalName.FORGOT_PASS]: 'forgot-pass',
  [ModalName.PHONE]: 'phone',
  [ModalName.DEPOSIT_ERROR]: 'deposit-error',
  [ModalName.DEPOSIT_SUCCESS]: 'deposit-success',
  [ModalName.DEPOSIT_STATUS]: 'deposit-status',
  [ModalName.REFERRAL_TRANSFER]: 'referral-transfer',
  [ModalName.CONFIRM]: 'confirm',
  [ModalName.WALLET]: 'wallet',
  [ModalName.WALLET_TELEGRAM_APP]: 'tg-wallet',
};

const MODALS_CONTENT: Partial<Record<ModalNameTypes, string>> = {
  [ModalName.CONFIRM]: 'profileConfirmed',
  [ModalName.FORGOT_PASS]: 'authorization',
  [ModalName.RESET_PASS]: 'authorization',
  [ModalName.CONFIRM_EMAIL]: 'authorization',
  [ModalName.RESET_EMAIL_SEND]: 'authorization',
};

const SYNC_MODALS: ModalTypes = {
  [ModalName.SIGN_IN]: true,
  [ModalName.SIGN_UP]: true,
  [ModalName.WALLET_TELEGRAM_APP]: true,
  [ModalName.WALLET]: true,
  [ModalName.TG_NEWS]: true,
};

const ONLY_GUEST_MODALS: ModalTypes = {
  [ModalName.SIGN_IN]: true,
  [ModalName.SIGN_UP]: true,
  [ModalName.RESET_PASS]: true,
  [ModalName.FORGOT_PASS]: true,
  [ModalName.RESET_EMAIL_SEND]: true,
};

const ONLY_LOGGED_MODALS: ModalTypes = {
  [ModalName.CHANGE_PASS]: true,
  [ModalName.PHONE]: true,
  [ModalName.DEPOSIT_ERROR]: true,
  [ModalName.DEPOSIT_SUCCESS]: true,
  [ModalName.REFERRAL_TRANSFER]: true,
  [ModalName.FIAT]: true,
  [ModalName.CONFIRM]: true,
  [ModalName.CONFIRM_EMAIL]: true,
  [ModalName.WALLET]: true,
  [ModalName.WALLET_TELEGRAM_APP]: true,
  [ModalName.CONFIRM_SESSION_LOGOUT]: true,
  [ModalName.AVATARS]: true,
};

export {
  ModalName,
  MODALS,
  MODALS_URL,
  ONLY_GUEST_MODALS,
  ONLY_LOGGED_MODALS,
  SYNC_MODALS,
  MODALS_CONTENT,
  type ModalNameTypes,
};
