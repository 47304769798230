export const useColorModeLogic = () => {
  const colorMode = useColorMode();
  const setColorMode = (colorModeValue: 'light' | 'dark' | 'system'): void => {
    colorMode.preference = colorModeValue;
    useEvent('accountChanged');
  }

  const getColorMode = (): string => {
    return colorMode.value;
  }

  const colorPreference = computed(():string => colorMode.preference);

  return {
    setColorMode,
    getColorMode,
    colorPreference
  }
}
