// import * as Sentry from '@sentry/nuxt';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  const sentryDsn = runtimeConfig.public.sentryDns as string;

  if (!sentryDsn) {
    console.warn('Sentry will not be initialized.');
    return;
  }

  // Sentry.init({
  //   dsn: sentryDsn,
  //   integrations: [
  //     Sentry.replayIntegration()
  //   ],
  //   environment: runtimeConfig.public.appMode,
  //   tracesSampleRate: runtimeConfig.public.appMode === 'prod' ? 0.1 : 1,
  //   tracePropagationTargets: [
  //     ...(runtimeConfig.public.appMode === 'prod' ? [] : ['localhost']),
  //     `${runtimeConfig.public.apiBaseUrl}/api`
  //   ],
  //   replaysSessionSampleRate: runtimeConfig.public.appMode === 'prod' ? 0.1 : 1,
  //   replaysOnErrorSampleRate: runtimeConfig.public.appMode === 'prod' ? 0.1 : 1,
  //   beforeSend(event) {
  //     if (event.request?.headers) {
  //       delete event.request.headers.Authorization;
  //     }
  //     return event;
  //   }
  // });

});