import type {
  IAuthorizationRequest,
  IAuthorizationResponse,
  IEmailOtpCodeVerifyRequest,
  IEmailVerifyRequest,
  IField,
  IOtpRequest,
  IPhoneVerification,
  ITgLoginData,
} from '../types';
import { useApiGuestInstance } from '@skeleton/core/assets/apiGuestInstance';
import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

export const useCoreAuthApi = () => {
  const getRegistrationFields = async (): Promise<IField[]> => {
    const { data } = await useApiGuestInstance(
      '/api/player/fields/validations',
      {
        params: { scenario: 'registration' },
      },
    );
    return data;
  };

  const submitRegistrationData = async (
    registrationFormData: any,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiGuestInstance('/api/player/register', {
      method: 'POST',
      body: registrationFormData,
    });

    return data;
  };

  const submitLoginData = async (
    authorizationFormData: IAuthorizationRequest,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiGuestInstance('/api/player/sessions', {
      method: 'POST',
      body: authorizationFormData,
    });

    return data;
  };

  const submitAutologinData = async (autoLoginData: {
    token: string;
    fingerprint?: string;
  }): Promise<IAuthorizationResponse> => {
    const { data } = await useApiGuestInstance('/api/player/sessions/token', {
      method: 'POST',
      body: autoLoginData,
    });

    return data;
  };

  const refreshToken = async (): Promise<IAuthorizationResponse> => {
    const profileStore = useProfileStore();
    const { getSessionToken } = profileStore;
    const token = getSessionToken();

    if (profileStore.isTelegramUser) {

      const body: { tg_payload?: string; affiliateTag?:string } = {
          tg_payload: profileStore.initDataRaw,
      };

      const { getFromStorage } = useBrowserStorage(true);
      const affiliateTag = getFromStorage('affiliateTag');

      if (affiliateTag) {
        body.affiliateTag = affiliateTag;
      }

      const { data }: { data: IAuthorizationResponse } = await $fetch(
        '/api/player/tg_login',
        {
          method: 'POST',
          body,
        }
      );

      return data;
    } else {
      const { data }: { data: IAuthorizationResponse } = await $fetch(
        '/api/player/sessions/refresh',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'POST',
        },
      );
      return data;
    }
  };

  const logOut = async (): Promise<{ message: string }> => {
    const { data } = await useApiAuthInstance('/api/player/sessions/logout', {
      method: 'POST',
    });

    return data;
  };

  const submitSocialLoginData = async (
    socialAuthData: any,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiGuestInstance('/api/player/sessions/social', {
      method: 'POST',
      body: socialAuthData,
    });

    return data;
  };

  const submitGoogleConnectionData = async (
    socialAuthData: any,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiAuthInstance('/api/player/social-connections/google', {
      method: 'POST',
      body: socialAuthData,
    });

    return data;
  };

  const unlinkSocial = async (socialType: string): Promise<void> => {
    await useApiAuthInstance('/api/player/social-connections/unlink', {
      method: 'POST',
      body: {
        socialType
      },
    });
  }

  const submitTelegramConnectionData = async (
    tgLoginData: ITgLoginData,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiAuthInstance('/api/player/social-connections/telegram', {
      method: 'POST',
      body: {
        payload: tgLoginData.initDataRaw
      },
    });

    return data;
  };

  const sendOtp = async (
    otpData: IOtpRequest,
  ): Promise<{ message: string }> => {
    const { data } = await useApiAuthInstance('/api/player/otp/send', {
      method: 'POST',
      body: otpData,
    });

    return data;
  };

  const registerByPhone = async (
    registrationFormData: any,
  ): Promise<IAuthorizationResponse> => {
    const { data } = await useApiGuestInstance('/api/player/register/phone', {
      method: 'POST',
      body: registrationFormData,
    });

    return data;
  };

  const phoneVerification = async (
    verificationData: IPhoneVerification,
  ): Promise<{ code: string }> => {
    const { data } = await useApiAuthInstance('/api/player/otp/verify', {
      method: 'POST',
      body: verificationData,
    });

    return data;
  };

  const sendEmailVerifyCode = async (
    emailFormData: IEmailVerifyRequest,
  ): Promise<{ message: string }> => {
    const { data } = await useApiAuthInstance(
      '/api/player/profile/email/verify/send',
      {
        method: 'POST',
        body: emailFormData,
      },
    );

    return data;
  };

  const sendEmailOTPCodeChangePass = async (): Promise<{ message: string }> => {
    const { data } = await useApiAuthInstance(
      '/api/player/otp/email/send',
      {
        method: 'POST'
      },
    );

    return data;
  };



  const emailOtpCodeVerification = async (
    formData: IEmailOtpCodeVerifyRequest,
  ): Promise<{ message: string }> => {
    const { data } = await useApiAuthInstance(
      '/api/player/profile/email/verify/confirm',
      {
        method: 'POST',
        body: formData,
      },
    );

    return data;
  };

  const submitTelegramLoginData = async (
    tgLoginData: ITgLoginData,
  ): Promise<IAuthorizationResponse> => {
    const route = useRoute();

    const { data } = await useApiGuestInstance('/api/player/tg_login', {
      method: 'POST',
      body: {
        tg_payload: tgLoginData.initDataRaw,
        affiliate_tag: tgLoginData?.affiliateTag ?? route.query?.stag,
        ref_id: tgLoginData?.refId,
      },
    });
    return data;
  };

  return {
    getRegistrationFields,
    submitRegistrationData,
    submitLoginData,
    submitAutologinData,
    refreshToken,
    logOut,
    submitSocialLoginData,
    submitTelegramConnectionData,
    sendOtp,
    registerByPhone,
    phoneVerification,
    submitTelegramLoginData,
    sendEmailVerifyCode,
    emailOtpCodeVerification,
    sendEmailOTPCodeChangePass,
    submitGoogleConnectionData,
    unlinkSocial
  };
};
