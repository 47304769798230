<template>
  <div>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const { currentLocale, countries, headerCountry } = storeToRefs(useGlobalStore());

  const isCurrentCountryRestrict = (): boolean | undefined => {
    if (!headerCountry.value) return false;
    const currentCountryObj = countries.value.find(country => country.code === headerCountry.value.toUpperCase());

    return currentCountryObj?.restrict;
  }
  const layout = isCurrentCountryRestrict() ? 'geo-restricted' : 'default';

  useHead({
    htmlAttrs: {
      lang: currentLocale.value?.code
    },
    bodyAttrs: {
      class: 'descendant',
    },
  })
</script>
