import type { IPaymentGroup } from '@skeleton/core/types';

export const prioritizeManualAgentMethods = (groups: IPaymentGroup[]) => {
  try {
    return groups.map((group) => {
      return {
        ...group,
        paymentMethods: group.paymentMethods.sort((a, b) => {
          if (a.method.includes('manual-agent')) return -1;
          if (b.method.includes('manual-agent')) return 1;
          return 0;
        })
      };
    });
  } catch (e) {
    return groups;
  }
};