import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as Sentry from '@sentry/nuxt';
import { Buffer } from 'buffer';

globalThis.Buffer = Buffer;

export default defineNuxtPlugin(nuxtApp => {
  const checkAffiliateTag = (): void => {
    const route = useRoute();
    const router = useRouter();
    const runtimeConfig = useRuntimeConfig();

    const { setToStorage, getFromStorage } = useBrowserStorage(true);

    const cookieAffiliateTag = getFromStorage('affiliateTag');

    if (route.query?.stag && !cookieAffiliateTag) {
      setToStorage('affiliateTag', route.query.stag as string, {
        maxAge: 60 * 60 * 24 * ((runtimeConfig.public.affiliateTagExpiration as number) || 30),
      });
    }

    if (route.query?.botURL) {
      setToStorage('botURL', route.query?.botURL as string);
    }

    if (route.query?.stag) router.replace({ query: { ...route.query, stag: undefined } });
  };

  const setWindowStaticHeight = (): void => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh-static', `${vh}px`);
  };

  const setWindowHeight = (): void => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const startProfileLogic = (): void => {
    const { getSessionToken } = useProfileStore();
    const sessionToken = getSessionToken();

    if (sessionToken) {
      const { startProfileDependencies } = useProfileStore();
      startProfileDependencies();
    }
  };

  const startFreshchatLogic = (): void => {
    const {
      public: { freshchatParams },
    } = useRuntimeConfig();
    const { addFreshChatScript, initChat } = useFreshchatStore();
    const { getSessionToken } = useProfileStore();
    const sessionToken = getSessionToken();

    if (freshchatParams?.guestAvailable) initChat();
    else if (sessionToken) addFreshChatScript();
  };

  const decodeBase64 = (value: string): string | undefined => {
    try {
      return window.atob(value);
    } catch {
      return undefined;
    }
  };

  const listeningChangeSession = async (event: StorageEvent): Promise<void> => {
    if (event.key !== 'changeSession' || !event.newValue) return;

    const decodeValue = decodeBase64(event.newValue);
    if (!decodeValue) return;

    const changeType = decodeValue.split('-')[1];
    const { isLoggedIn } = useProfileStore();
    const loginParallel = !isLoggedIn && changeType === 'login';
    const logoutParallel = isLoggedIn && changeType === 'logout';

    if (loginParallel) {
      const router = useRouter();
      router.go(0);
    } else if (logoutParallel) {
      const { localizePath } = useProjectMethods();
      window.location.href = window.location.origin + localizePath('/');
    }
  };

  const getFingerprintVisitor = async (): Promise<string> => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  };

  const checkTabVisibility = (): void => {
    const deviceStore = useDeviceStore();
    const { isMobile } = storeToRefs(deviceStore);
    const { isLoggedIn } = useProfileStore();
    if (isMobile.value && isLoggedIn && document.visibilityState === 'visible') {
      const { getUserAccounts } = useWalletStore();
      getUserAccounts();
    }
  };

  const initTelegramApp = async (): Promise<void> => {
    const telegramApp = window?.Telegram?.WebApp;
    const profileStore = useProfileStore();

    const initData = profileStore.telegram.initDataRaw || telegramApp?.initData;

    if (!initData) {
      return;
    }

    const { getFromStorage, setToStorage } = useBrowserStorage();

    try {
      const initDataFromSessionStorage = getFromStorage('initData');

      if (initData === 'query_id' && initDataFromSessionStorage) {
        profileStore.telegram.initDataRaw = initDataFromSessionStorage;
        profileStore.telegram.initData = JSON.parse(getFromStorage('initDataUnsafe') || '');
      } else {
        profileStore.telegram.initDataRaw = initData;
        profileStore.telegram.initData = telegramApp?.initDataUnsafe;
        setToStorage('initData', initData);
        setToStorage('initDataUnsafe', JSON.stringify(telegramApp?.initDataUnsafe));
      }

      profileStore.telegram.platform = telegramApp?.platform;

      setupTelegramWebApp(telegramApp);

      window?.TelegramWebviewProxy?.postEvent(
        'web_app_setup_closing_behavior',
        JSON.stringify({ need_confirmation: true })
      );
    } catch (e) {
      profileStore.telegram.initDataRaw = undefined;
      profileStore.telegram.initData = undefined;

      Sentry.withScope(scope => {
        scope.setLevel('error');
        scope.setContext('TgInit error', {
          initData,
        });
        scope.captureException(e);
      });

      console.log('TgInit error', e);
    }

    await nextTick();

    const token = profileStore.getSessionToken();

    if (!profileStore.isLoggedIn && !token && profileStore.isTelegramUser) {
      await profileStore.loginFromTelegramApp('Setup client');
    }

    if (!profileStore.isLoggedIn && token) {
      const { getProfileData } = useProfileStore();
      const { getUserAccounts } = useWalletStore();

      try {
        await Promise.all([getProfileData(), getUserAccounts()]);
      } catch {
        console.log('initTelegramApp: User logged out!');
      }
    }
  };

  const setupTelegramWebApp = (telegramApp: IWebApp): void => {
    telegramApp?.ready();
    telegramApp?.expand();

    try {
      telegramApp?.disableVerticalSwipes();
      autoFullscreen(telegramApp);
      setSafeArea(telegramApp);

      telegramApp?.onEvent('contentSafeAreaChanged', () => {
        setSafeArea(telegramApp);
      });

      telegramApp?.onEvent('viewportChanged', event => {
        if (event.isStateStable) {
          setSafeArea(telegramApp);
        }
      });
    } catch (e) {
      console.log('Error setupTelegramWebApp', e);
    }
  };

  const autoFullscreen = (tg: IWebApp): void => {
    tg.requestFullscreen();
  };

  const setSafeArea = (tg: IWebApp): void => {
    const contentSafeAreaTop = tg.contentSafeAreaInset?.top || 0;
    const safeAreaTop = tg.safeAreaInset?.top || 0;

    const contentSafeAreaBottom = tg.contentSafeAreaInset?.bottom || 0;
    const safeAreaBottom = tg.safeAreaInset?.bottom || 0;

    document.documentElement.style.setProperty('--tg-final-safe-area-top', `${contentSafeAreaTop + safeAreaTop}px`);
    document.documentElement.style.setProperty(
      '--tg-final-safe-area-bottom',
      `${contentSafeAreaBottom + safeAreaBottom}px`
    );

    setBodyVariableStyle();
  };

  const setBodyVariableStyle = (): void => {
    const navMob = document.querySelector('.nav-mob') as HTMLElement;

    document.body.style.cssText += `
      --nav-bar-bottom-height: ${navMob.offsetHeight}px;
    `;
  };

  nuxtApp.hook('app:created', async () => {
    const { getCollectionsList } = useGamesStore();
    getCollectionsList();

    const profileStore = useProfileStore();
    profileStore.fingerprintVisitor = getFingerprintVisitor();
  });

  nuxtApp.hook('app:mounted', async () => {
    // tg-init
    const { initWebSocket } = useWebSocket();
    await initWebSocket();

    checkAffiliateTag();

    await initTelegramApp();

    // переключит контент в google-web-telegram если был выбран другой язык
    const profileStore = useProfileStore();
    const { setRefreshingState, setCurrentLocale, getTelegramLanguage, getGlobalContent } = useGlobalStore();

    // обновит контент из выбранного юзером языка на клиенте
    if (profileStore.isTelegramUser) {
      getTelegramLanguage();
      setCurrentLocale();
      await getGlobalContent();
      setRefreshingState(true);
      await refreshNuxtData();
      setRefreshingState(false);
    }

    // old logic
    window.addEventListener('storage', listeningChangeSession);

    startProfileLogic();
    setWindowStaticHeight();
    setWindowHeight();
    window.addEventListener('resize', setWindowHeight);
    startFreshchatLogic();
    window.addEventListener('visibilitychange', checkTabVisibility);

    const { initAltenarScript, updateAltenarToken } = useAltenarStore();
    await initAltenarScript();

    useListen('accountChanged', async () => {
      await updateAltenarToken();
    });
  });

  nuxtApp.hook('page:finish', () => {
    const route = useRoute();
    const autologinRoute = route.name === 'auth-autologin' || route.name === 'locale-auth-autologin';
    const callbackRoute = route.name === 'auth-callback' || route.name === 'locale-auth-callback';
    const isAuthAutologin = autologinRoute && !!route.query.state;
    const isAuthCallback = callbackRoute && !!route.query.code && !!route.query.state;

    if (isAuthAutologin || isAuthCallback) return;

    setTimeout(() => {
      const { hidePreloader } = useGlobalStore();
      hidePreloader();
    }, 1000);
  });
});
