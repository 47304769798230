export default defineNuxtRouteMiddleware(async event => {
  const nuxtApp = useNuxtApp();

  if (import.meta.server || !nuxtApp.isHydrating) return;

  const profileStore = useProfileStore();

  const botUrl = Array.isArray(event.query.botURL) ? event.query.botURL[0] : event.query.botURL;

  if (botUrl) {
    profileStore.telegram.botUrl = botUrl;
    profileStore.telegram.initDataRaw = window?.Telegram?.WebApp.initData;
  }

  const token = profileStore.getSessionToken();

  if (!profileStore.isLoggedIn && token) {
    const { getProfileData } = useProfileStore();
    const { getUserAccounts } = useWalletStore();

    try {
      await Promise.all([getProfileData(), getUserAccounts()]);
    } catch {
      console.log('GS: User logged out!');
    }
  }
});
