export const useAdditionParams = () => {
  const URLS: string[] = ['payment/methods/deposit', 'wallet/accounts', 'sessions', 'register', 'player/tg_login'];
  const getRequestParams = (url: string) => {
    const res: { source: string | undefined; platform: string | undefined } = {
      source: undefined,
      platform: undefined,
    };

    if (import.meta.server) return res;

    const containsUrl = URLS.some(item => url.includes(item));

    if (!containsUrl) return res;

    const profileStore = useProfileStore();
    res.source = profileStore.telegram.botUrl || window?.location.origin;
    res.platform = profileStore.telegram.botUrl ? 'tg' : 'web';

    return res;
  };

  return { getRequestParams };
};
