
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93PlAgtvnoziMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinNP5JkPDAr9Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackbDDTqx1nc5Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettinguihxyPduhAMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93bqTaeSiaNjMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as bonusesNP8tCxEtbQMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/bonuses.vue?macro=true";
import { default as contactO7RV1m7JPtMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46client3rpsM7Ke5ZMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93h6RZ3jYX25Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/game/[id].vue?macro=true";
import { default as _91_46_46_46params_934fskd2IFVIMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/[...params].vue?macro=true";
import { default as indexSIjNTYveKNMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/index.vue?macro=true";
import { default as _91providerIdentity_93xvB9A2082CMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/providers/[providerIdentity].vue?macro=true";
import { default as indexVEFedmabMLMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/providers/index.vue?macro=true";
import { default as indexRihZhal2OWMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/index.vue?macro=true";
import { default as loyaltyEUwE1zUgcPMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/loyalty.vue?macro=true";
import { default as main9sv7qEaOb5Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/main.vue?macro=true";
import { default as infopyQ1kExXVDMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsz7npLyQTluMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/limits.vue?macro=true";
import { default as personal8Q4Uz4Rc2dMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/personal.vue?macro=true";
import { default as sessionskVNRlSDK8oMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/sessions.vue?macro=true";
import { default as accountp0a5ouB9MQMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings/account.vue?macro=true";
import { default as notifications0fHezF3zmBMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings/notifications.vue?macro=true";
import { default as settingsWwOsQd97tLMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings.vue?macro=true";
import { default as profileUMyrhC5WjVMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile.vue?macro=true";
import { default as _91pageIdentity_93UF3XZCerzbMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questions0fHGncZODdMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientu1BFH2JAFHMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/recently-played.client.vue?macro=true";
import { default as redirect_46clientHcWToCsrrxMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/redirect.client.vue?macro=true";
import { default as referralJCAqQZ2BSNMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/referral.vue?macro=true";
import { default as sportGlcg4VH6j5Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/sport.vue?macro=true";
import { default as _91pageIdentity_9378I3O2JDLDMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as supportAPQon9Z49BMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/support.vue?macro=true";
import { default as historyBopDYrmUlLMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet/history.vue?macro=true";
import { default as indexdK9A79GGA6Meta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet/index.vue?macro=true";
import { default as walletyP47OLKnujMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet.vue?macro=true";
import { default as welcome_45packagexZK3R4GrbFMeta } from "/builds/platform/customer-frontends/tg-wincity/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "bonuses",
    path: "/bonuses",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/bonuses.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "game-id",
    path: "/game/:id()",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/game/[id].vue")
  },
  {
    name: "games-params",
    path: "/games/:params(.*)*",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/[...params].vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/index.vue")
  },
  {
    name: "games-providers-providerIdentity",
    path: "/games/providers/:providerIdentity()",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/providers/[providerIdentity].vue")
  },
  {
    name: "games-providers",
    path: "/games/providers",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/games/providers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile.vue"),
    children: [
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-personal",
    path: "personal",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/personal.vue")
  },
  {
    name: "profile-sessions",
    path: "sessions",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/sessions.vue")
  },
  {
    name: "profile-settings",
    path: "settings",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings.vue"),
    children: [
  {
    name: "profile-settings-account",
    path: "account",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings/account.vue")
  },
  {
    name: "profile-settings-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/profile/settings/notifications.vue")
  }
]
  }
]
  },
  {
    name: "questions",
    path: "/questions",
    meta: questions0fHGncZODdMeta || {},
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirect_46clientHcWToCsrrxMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/redirect.client.vue"))
  },
  {
    name: "referral",
    path: "/referral",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/referral.vue")
  },
  {
    name: "sport",
    path: "/sport",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/sport.vue")
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/support.vue")
  },
  {
    name: walletyP47OLKnujMeta?.name,
    path: "/wallet",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet.vue"),
    children: [
  {
    name: "wallet-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet/history.vue")
  },
  {
    name: "wallet",
    path: "",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/wallet/index.vue")
  }
]
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/tg-wincity/skeleton/pages/welcome-package.vue")
  }
]