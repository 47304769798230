export const EnvName = {
  PROD: 'prod',
  STAGE: 'stage',
  DEV: 'dev'
};

export const AltenarUrl = {
  PROD: 'https://sb2wsdk-altenar2.biahosted.com/altenarWSDK.js',
  DEV: 'https://sb2wsdk-altenar2-stage.biahosted.com/altenarWSDK.js'
};
