import { useBreakpoints } from '@vueuse/core';

// **Определяем breakpoints (совпадают с SCSS)**
const breakpoints = useBreakpoints({
  xs: 528,
});

const getUserAgentMobile = (): boolean => {
  if (typeof navigator === 'undefined') return false;
  const agent: string = navigator.userAgent.toLowerCase();
  return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(agent);
};

export const useDeviceStore = defineStore('deviceStore', () => {
  const userAgentMobile = ref(false);

  const isMobile = computed<boolean>(() => {
    return breakpoints.smaller('xs').value || userAgentMobile.value;
  });

  onMounted(() => {
    userAgentMobile.value = getUserAgentMobile();
  });

  return { isMobile };
});
