import type { CookieOptions } from '#app';

export const useBrowserStorage = (isLocalStorage = false) => {

  const profileStore = useProfileStore();

  const getStorage = () => {
    if (!window) {
      return undefined;
    }

    return isLocalStorage || profileStore.isTelegramUser ? localStorage : sessionStorage;
  };

  const setToStorage = (name: string, value: string, options?: CookieOptions & { readonly?: boolean }) => {
    if (profileStore.isTelegramUser) {
      getStorage()?.setItem(name, value);
    } else if (options?.readonly === true) {
      console.warn(`Cannot set value for read-only cookie: ${name}`);
    } else {
      const cookie = useCookie(name, { ...options, readonly: false });
      cookie.value = value;
    }
  };

  const getFromStorage = (key: string) => {
    if (profileStore.isTelegramUser) {
      return getStorage()?.getItem(key);
    } else {
      const cookie = useCookie(key);
      return cookie.value;
    }
  };

  return {
    setToStorage,
    getFromStorage
  };
};
