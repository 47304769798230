export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter();
  const loadingStore = useLoadingStore();

  router.beforeEach(async (to, from, next) => {
    loadingStore.setRouteName(to.name);

    next();
  });
});
