const widgetBgPath = '/sports/widgetBackgrounds/';
const cardsBgPath = '/sports/cardsBackgrounds/';
const cardsImgPath = '/sports/cardsImages/';

export const BannerEventBoxDefault = {
  timeColor: '#fff',
  dateColor: '#fff',
  championshipColor: '#fff',
  competitorColor: '#fff',
};

export interface ISportConfigItem {
  id: number;
  name: string;
  code: string;
  categories?: {
    categoryId: number;
    name: string;
    code: string;
    widgetBgUrl?: string;
  }[];
  widgetBgUrl?: string;
  cardBgUrl?: string;
  cardImgUrl?: string;
}

export const sportsList: ISportConfigItem[] = [
  {
    id: 66,
    name: 'Soccer',
    code: 'soccer',
    widgetBgUrl: `${widgetBgPath}soccer.png`,
    cardBgUrl: `${cardsBgPath}soccer.png`,
    cardImgUrl: `${cardsImgPath}soccer.png`,
  },
  {
    id: 67,
    name: 'Basketball',
    code: 'basketball',
    widgetBgUrl: `${widgetBgPath}basketball.png`,
    cardBgUrl: `${cardsBgPath}basketball.png`,
    cardImgUrl: `${cardsImgPath}basketball.png`,
  },
  {
    id: 68,
    name: 'Tennis',
    code: 'tennis',
    widgetBgUrl: `${widgetBgPath}tennis.png`,
    cardBgUrl: `${cardsBgPath}tennis.png`,
    cardImgUrl: `${cardsImgPath}tennis.png`,
  },
  {
    id: 69,
    name: 'Volleyball',
    code: 'volleyball',
    widgetBgUrl: `${widgetBgPath}volleyball.png`,
    cardBgUrl: `${cardsBgPath}volleyball.png`,
    cardImgUrl: `${cardsImgPath}volleyball.png`,
  },
  {
    id: 70,
    name: 'Ice Hockey',
    code: 'ice-hockey',
    widgetBgUrl: `${widgetBgPath}ice-hockey.png`,
    cardBgUrl: `${cardsBgPath}ice-hockey.png`,
    cardImgUrl: `${cardsImgPath}ice-hockey.png`,
  },
  {
    id: 71,
    name: 'Boxing',
    code: 'boxing',
    widgetBgUrl: `${widgetBgPath}boxing.png`,
    cardBgUrl: `${cardsBgPath}boxing.png`,
    cardImgUrl: `${cardsImgPath}boxing.png`,
  },
  {
    id: 72,
    name: 'Badminton',
    code: 'badminton',
    widgetBgUrl: `${widgetBgPath}badminton.png`,
  },
  {
    id: 73,
    name: 'Handball',
    code: 'handball',
    widgetBgUrl: `${widgetBgPath}handball.png`,
  },
  {
    id: 74,
    name: 'Cricket',
    code: 'cricket',
    widgetBgUrl: `${widgetBgPath}cricket.png`,
    cardBgUrl: `${cardsBgPath}cricket.png`,
    cardImgUrl: `${cardsImgPath}cricket.png`,
  },
  {
    id: 75,
    name: 'American Football',
    code: 'american-football',
    widgetBgUrl: `${widgetBgPath}american-football.png`,
    cardBgUrl: `${cardsBgPath}american-football.png`,
    cardImgUrl: `${cardsImgPath}american-football.png`,
  },
  {
    id: 76,
    name: 'Baseball',
    code: 'baseball',
    widgetBgUrl: `${widgetBgPath}baseball.png`,
    cardBgUrl: `${cardsBgPath}baseball.png`,
    cardImgUrl: `${cardsImgPath}baseball.png`,
  },
  {
    id: 77,
    name: 'Table Tennis',
    code: 'table-tennis',
    widgetBgUrl: `${widgetBgPath}table-tennis.png`,
    cardBgUrl: `${cardsBgPath}table-tennis.png`,
    cardImgUrl: `${cardsImgPath}table-tennis.png`,
  },
  {
    id: 78,
    name: 'Darts',
    code: 'darts',
    widgetBgUrl: `${widgetBgPath}darts.png`,
  },
  {
    id: 79,
    name: 'Bandy',
    code: 'bandy',
  },
  {
    id: 80,
    name: 'Floorball',
    code: 'floorball',
    widgetBgUrl: `${widgetBgPath}floorball.png`,
  },
  {
    id: 81,
    name: 'Snooker',
    code: 'snooker',
    widgetBgUrl: `${widgetBgPath}snooker.png`,
  },
  {
    id: 82,
    name: 'Beach Volley',
    code: 'beach-volley',
  },
  {
    id: 83,
    name: 'Waterpolo',
    code: 'waterpolo',
  },
  {
    id: 84,
    name: 'MMA',
    code: 'mma',
    widgetBgUrl: `${widgetBgPath}mma.png`,
    cardBgUrl: `${cardsBgPath}mma.png`,
    cardImgUrl: `${cardsImgPath}mma.png`,
  },
  {
    id: 85,
    name: 'Golf',
    code: 'golf',
    widgetBgUrl: `${widgetBgPath}golf.png`,
  },
  {
    id: 86,
    name: 'Curling',
    code: 'curling',
  },
  {
    id: 87,
    name: 'Squash',
    code: 'squash',
    widgetBgUrl: `${widgetBgPath}squash.png`,
  },

  { id: 88, name: 'Futsal', code: 'futsal', widgetBgUrl: `${widgetBgPath}futsal.png` },
  { id: 89, name: 'Cycling', code: 'cycling', widgetBgUrl: `${widgetBgPath}cycling.png` },
  { id: 90, name: 'Specials', code: 'specials' },
  { id: 91, name: 'Ski Jumping', code: 'ski-jumping', widgetBgUrl: `${widgetBgPath}ski-jumping.png` },
  { id: 92, name: 'Alpine Skiing', code: 'alpine-skiing', widgetBgUrl: `${widgetBgPath}alpine-skiing.png` },
  {
    id: 93,
    name: 'Biathlon',
    code: 'biathlon',
    widgetBgUrl: `${widgetBgPath}biathlon.png`,
    cardBgUrl: `${cardsBgPath}biathlon.png`,
    cardImgUrl: `${cardsImgPath}biathlon.png`,
  },
  { id: 94, name: 'Beach Soccer', code: 'beach-soccer' },
  { id: 95, name: 'Fencing', code: 'fencing' },
  { id: 96, name: 'E-Sports', code: 'e-sports' },
  { id: 99, name: 'Archery', code: 'archery' },
  { id: 100, name: 'Greyhounds', code: 'greyhounds', widgetBgUrl: `${widgetBgPath}greyhounds.png` },
  { id: 101, name: 'Rugby League', code: 'rugby-league' },
  { id: 102, name: 'Rugby Union', code: 'rugby-union' },
  {
    id: 103,
    name: 'Motorsport',
    code: 'motorsport',
    widgetBgUrl: `${widgetBgPath}motorsport.png`,
    cardBgUrl: `${cardsBgPath}motorsport.png`,
    cardImgUrl: `${cardsImgPath}motorsport.png`,
  },
  { id: 105, name: '3x3 Basketball', code: '3x3-basketball' },
  { id: 106, name: 'Aussie Rules', code: 'aussie-rules', widgetBgUrl: `${widgetBgPath}aussie-rules.png` },
  { id: 107, name: 'Field Hockey', code: 'field-hockey' },
  { id: 108, name: 'Bowls', code: 'bowls' },
  { id: 109, name: 'Pesapallo', code: 'pesapallo' },
  { id: 110, name: 'Athletics', code: 'athletics' },
  { id: 111, name: 'Sailing', code: 'sailing' },
  { id: 112, name: 'Football AI', code: 'football-ai' },
  { id: 113, name: 'Basketball AI', code: 'basketball ai' },
  { id: 115, name: 'Boosted Odds', code: 'boosted-odds' },
  { id: 116, name: 'Gaelic Football', code: 'gaelic-football' },
  { id: 117, name: 'Gaelic Hurling', code: 'gaelic-hurling' },
  {
    id: 118,
    name: 'Cross Country',
    code: 'cross-country',
    widgetBgUrl: `${widgetBgPath}cross-country-running.png`,
  },
  { id: 119, name: 'Nordic Combined', code: 'nordic-combined' },
  { id: 120, name: 'WWE', code: 'wwe' },
  { id: 121, name: 'Price Boosts', code: 'price-boosts' },
  { id: 122, name: 'Flying Odds', code: 'flying-odds' },
  { id: 123, name: 'Softball', code: 'softball' },
  { id: 124, name: 'Golden Odds', code: 'golden-odds' },
  { id: 125, name: 'Olympics', code: 'olympics' },
  { id: 126, name: 'Swimming', code: 'swimming' },
  { id: 127, name: 'Equestrian', code: 'equestrian' },
  { id: 128, name: 'Skateboarding', code: 'skateboarding' },
  { id: 129, name: 'Surfing', code: 'surfing' },
  { id: 130, name: 'Rowing', code: 'rowing' },
  { id: 131, name: 'Triathlon', code: 'triathlon' },
  { id: 132, name: 'Weightlifting', code: 'weightlifting' },
  { id: 133, name: 'Marathon Swimming', code: 'marathon-swimming' },
  { id: 134, name: 'Tennis AI', code: 'tennis-ai' },
  { id: 135, name: 'Canoeing', code: 'canoeing' },
  { id: 136, name: 'Taekwondo', code: 'taekwondo' },
  { id: 137, name: 'Diving', code: 'diving' },
  { id: 138, name: 'Wrestling', code: 'wrestling' },
  { id: 139, name: 'Gymnastics', code: 'gymnastics' },
  { id: 140, name: 'Shooting', code: 'shooting' },
  { id: 141, name: 'Judo', code: 'judo' },
  { id: 142, name: 'Modern Pentathlon', code: 'modern pentathlon' },
  { id: 144, name: 'Financial Bets', code: 'financial-bets' },
  {
    id: 145,
    name: 'E-sports +',
    code: 'e-sports+',
    categories: [
      {
        categoryId: 1706,
        name: 'Counter-Strike',
        code: 'cs2',
        widgetBgUrl: `${widgetBgPath}cs2.png`,
      },
      {
        categoryId: 1533,
        name: 'Dota2',
        code: 'dota2',
        widgetBgUrl: `${widgetBgPath}dota2.png`,
      },
      {
        categoryId: 1330,
        name: 'Valorant',
        code: 'valorant',
        widgetBgUrl: `${widgetBgPath}valorant.png`,
      },
      {
        categoryId: 1307,
        name: 'Hearthstone',
        code: 'hearthstone',
        widgetBgUrl: `${widgetBgPath}hearthstone.png`,
      },
    ],
    widgetBgUrl: `${widgetBgPath}esport.png`,
    cardBgUrl: `${cardsBgPath}esport.png`,
    cardImgUrl: `${cardsImgPath}esport.png`,
  },
  { id: 146, name: 'E-Football', code: 'e-football' },
  { id: 147, name: 'E-Basketball', code: 'e-basketball' },
  { id: 148, name: 'E-Ice Hockey', code: 'e-ice-hockey' },
  { id: 149, name: 'Premium Cricket', code: 'premium-cricket' },
  { id: 150, name: 'Cross Cycling', code: 'cross-cycling' },
  { id: 151, name: 'Lacrosse', code: 'lacrosse' },
  { id: 152, name: 'Bobsleigh', code: 'bobsleigh' },
  { id: 154, name: 'Freestyle Skiing', code: 'freestyle-skiing' },
  { id: 155, name: 'Figure Skating', code: 'figure-skating' },
  { id: 156, name: 'Snowboarding', code: 'snowboarding' },
  { id: 157, name: 'Skeleton', code: 'skeleton' },
  { id: 158, name: 'Speed Skating', code: 'speed-skating' },
  { id: 159, name: 'Short Track Speed Skating', code: 'short-track-speed-skating' },
  {
    id: 160,
    name: 'Kabaddi',
    code: 'kabaddi',
    cardBgUrl: `${cardsBgPath}kabaddi.png`,
    cardImgUrl: `${cardsImgPath}kabaddi.png`
  },
  { id: 161, name: 'Chess', code: 'chess' },
  { id: 162, name: 'Luge', code: 'luge' },
  { id: 163, name: 'Lotteries', code: 'lotteries', widgetBgUrl: `${widgetBgPath}lotteries.png` },
  { id: 164, name: 'Padel', code: 'padel' },
  { id: 165, name: 'Horse Racing', code: 'horse-racing', widgetBgUrl: `${widgetBgPath}horse-racing.png` },
  { id: 166, name: 'Special New Customers', code: 'special-new-customers' },
  { id: 167, name: 'E-Tennis', code: 'e-tennis' },
  { id: 168, name: 'E-Volleyball', code: 'e-volleyball' },
  { id: 169, name: 'Specials 3', code: 'specials-3' },
  { id: 170, name: 'E-Cricket', code: 'e-cricket' },
  { id: 171, name: 'E-Table Tennis', code: 'e-table-tennis' },
  { id: 172, name: 'Trotting', code: 'trotting' },
  { id: 173, name: 'Pickleball', code: 'pickleball' },
  { id: 174, name: 'Specials 2', code: 'specials 2' },
  { id: 175, name: 'X1', code: 'x1' },
  { id: 176, name: 'Breaking', code: 'breaking' },
  { id: 177, name: 'Climbing', code: 'climbing' },
  { id: 178, name: 'Artistic Swimming', code: 'artistic-swimming' },
  { id: 179, name: 'Short Football', code: 'short-football' },
];
