import revive_payload_client_4sVQNw7RlN from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/platform/customer-frontends/tg-wincity/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/platform/customer-frontends/tg-wincity/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/builds/platform/customer-frontends/tg-wincity/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ghbUAjaD3n from "/builds/platform/customer-frontends/tg-wincity/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_hwlJ5PmHMh from "/builds/platform/customer-frontends/tg-wincity/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/builds/platform/customer-frontends/tg-wincity/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import analytics_WNqUsuh52u from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/analytics.ts";
import auth0_client_WDg96YyoDn from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/auth0.client.ts";
import chatra_client_iKSbHL3kQN from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/chatra.client.ts";
import click_outside_directive_84UULQwVnu from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/click-outside-directive.ts";
import error_handling_BTSyY7nAPP from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/error-handling.ts";
import fullStory_client_T5PnW3mGCK from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/fullStory.client.ts";
import gtmAnalytics_iBLTNKiJvi from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/gtmAnalytics.ts";
import hammer_client_tiD1MPW6aH from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/hammer.client.ts";
import maska_fpcrNrWUJx from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/maska.ts";
import router_tracking_7R40hmnkz1 from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/router-tracking.ts";
import sentry_client_hoReNdiP85 from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/sentry.client.ts";
import setup_client_q4OWF4a4wm from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/setup.client.ts";
import vue_final_modal_Sd9bDDO4NH from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/vue-final-modal.ts";
import vue_notify_5ZKF81RpmH from "/builds/platform/customer-frontends/tg-wincity/skeleton/plugins/vue-notify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_ghbUAjaD3n,
  plugin_hwlJ5PmHMh,
  plugin_Jozdw60ZsE,
  analytics_WNqUsuh52u,
  auth0_client_WDg96YyoDn,
  chatra_client_iKSbHL3kQN,
  click_outside_directive_84UULQwVnu,
  error_handling_BTSyY7nAPP,
  fullStory_client_T5PnW3mGCK,
  gtmAnalytics_iBLTNKiJvi,
  hammer_client_tiD1MPW6aH,
  maska_fpcrNrWUJx,
  router_tracking_7R40hmnkz1,
  sentry_client_hoReNdiP85,
  setup_client_q4OWF4a4wm,
  vue_final_modal_Sd9bDDO4NH,
  vue_notify_5ZKF81RpmH
]