import { storeToRefs } from 'pinia';
import { defineNuxtPlugin } from 'nuxt/app';

import { FullStory, init } from '@fullstory/browser';

import type { IProfile } from '@skeleton/core/types';

export default defineNuxtPlugin(() => {
    if (import.meta.client) {
        const runtimeConfig = useRuntimeConfig();
        const { fullStoryOrgId } = runtimeConfig.public;

        if (!fullStoryOrgId) return;

        init({ orgId: fullStoryOrgId });

        const profileStore = useProfileStore();
        const { profile } = storeToRefs(profileStore);

        watch(profile, (profile: Maybe<IProfile>) => {
            if (!profile) {
                FullStory('setIdentity', {
                    anonymous: true,
                });

                return;
            }

            const { id, email, phone, firstName, lastName } = profile;

            if (id) {
                FullStory('setIdentity', {
                    uid: id,
                    properties: {
                        firstName,
                        lastName,
                        email,
                        phone,
                    },
                });
            } else {
                FullStory('setIdentity', {
                    anonymous: true,
                });
            }
        });
    }
});
