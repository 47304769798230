export const useHaptics = () => {
  const profileStore = useProfileStore();
  const taptic = (type: 'light' | 'medium' | 'heavy' | 'rigid' | 'soft') => {
    if (!profileStore.isTelegramUser) return;

    try {
      window.TelegramWebviewProxy?.postEvent(
        'web_app_trigger_haptic_feedback',
        JSON.stringify({ type: 'impact', impact_style: type })
      );
    } catch (e) {
      console.log('Haptic error');
    }
  };

  return {
    taptic,
  };
};
